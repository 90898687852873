import './bootstrap';
import '../css/app.css';

import {createApp, h, inject, provide} from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { VTooltip } from 'v-tooltip';
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        app.directive('tooltip', VTooltip)

        Sentry.init({
            app,
            dsn: "https://79516da33e94ebac545cd559e5b797e6@o4505698977906688.ingest.us.sentry.io/4507110171082752",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/cloud.mobilize\.ai/],
            // Session Replay
            replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            trackComponents: true,
            logErrors: true,
        });

        app.use(plugin).use(ZiggyVue, Ziggy).mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});


